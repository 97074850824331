import React, { useEffect, useState } from 'react';
import { PageProps, graphql } from 'gatsby';

import { PageLayout } from '../components/PageLayout';
import { SearchResults } from '../components/SearchResults';
import { LoadingSpinner } from '../components/LoadingSpinner';

interface DataProps {
  localSearchBlogPosts: {
    publicStoreURL: string;
    publicIndexURL: string;
  };
}

interface LocationState {
  searchQuery: string | undefined;
}

const Search = ({
  data,
  location,
}: PageProps<DataProps, any, LocationState>) => {
  const [store, setStore] = useState<any>();
  const [index, setIndex] = useState<any>();

  const [searchQuery, setSearchQuery] = useState(
    location?.state?.searchQuery ?? ''
  );

  // fetch store and index from the url
  useEffect(() => {
    let isMounted = true;

    const fetchStore = async () => {
      const response = await fetch(data.localSearchBlogPosts.publicStoreURL);
      const store = await response.json();
      if (isMounted) {
        setStore(store);
      }
    };

    const fetchIndex = async () => {
      const response = await fetch(data.localSearchBlogPosts.publicIndexURL);
      const index = await response.text();
      if (isMounted) {
        setIndex(index);
      }
    };

    fetchStore();
    fetchIndex();

    return () => {
      isMounted = false;
    };
  }, [data.localSearchBlogPosts]);

  return (
    <PageLayout
      title="Suche"
      description="Finde Antworten von Frau Freitag auf fast tausend drängende Fragen des Lebens."
    >
      <div>
        <div className="relative">
          <input
            className="bg-slate-50 focus:outline-none focus:shadow-outline border-2 border-slate-300 focus:border-slate-500 rounded-3xl py-2 px-4 w-full appearance-none leading-normal"
            type="text"
            placeholder="Suche…"
            name="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoFocus
            autoComplete="off"
          />
          {searchQuery.length > 0 && (!store || !index) && (
            <div className="absolute top-0 right-0 px-4 py-2 border-2 border-transparent">
              <LoadingSpinner />
            </div>
          )}
        </div>
        {store && index && (
          <SearchResults
            searchQuery={searchQuery}
            store={store}
            index={index}
          />
        )}
      </div>
    </PageLayout>
  );
};

export const query = graphql`
  query {
    localSearchBlogPosts {
      publicIndexURL
      publicStoreURL
    }
  }
`;

export default Search;
