import React from 'react';

import { useFlexSearch } from 'react-use-flexsearch';

import { BlogPostOverview } from '../model';
import { BlogPostsList } from './BlogPostsList';

interface SearchResultsProps {
  searchQuery: string;
  index: any;
  store: any;
}

export const SearchResults = ({
  searchQuery,
  index,
  store,
}: SearchResultsProps) => {
  const results: ReadonlyArray<BlogPostOverview> = useFlexSearch(
    searchQuery,
    index,
    store
  );

  return (
    <>
      {results.length > 0 && (
        <h1 className="text-lg font-semibold mt-6">
          {results.length} Antworten gefunden
        </h1>
      )}
      <div>
        <BlogPostsList
          blogPosts={results}
          emptyText={
            searchQuery.length > 0
              ? `Keine Antworten für «${searchQuery}» gefunden.`
              : 'Gib einen Suchbegriff ein - zum Beispiel «Huhn oder Ei?» - um Antworten zu finden.'
          }
        />
      </div>
    </>
  );
};
